import LineGradient from "../components/LineGradient";
import { motion } from "framer-motion";

const container = {
  hidden: {},
  visible: {
    transition: { staggerChildren: 0.2 }
  }
};

const projectVariant = {
  hidden: { opacity: 0, scale: 0.8 },
  visible: { opacity: 1, scale: 1 }
};

// TODO: Add subTitle text in second p tag
const Project = ({ title, subTitle, liveSite, gitHub }) => {
  const overlayStyles = `absolute h-full w-full opacity-0 hover:opacity-90 transition duration-500
    bg-grey z-30 flex flex-col justify-center items-center text-center p-16 text-deep-blue`;
  const projectTitle = title.split(" ").join("-").toLowerCase();

  return (
    <motion.div variants={projectVariant} className="relative">
      <div className={overlayStyles}>
        <p className="text-2xl font-playFair">{title}</p>
        <p className="mt-7">
          {subTitle}
        </p>
        <div className="flex flex-row mt-2">
          <button className="bg-red text-white p-4 font-playFair rounded-md"><a href={liveSite} target="_blank" rel="noreferrer">Live</a></button>
          <button className="bg-blue text-white p-4 font-playFair rounded-md ml-2"><a href={gitHub} target="_blank" rel="noreferrer">Github</a></button>
        </div>
      </div>
      <img src={`../assets/${projectTitle}.jpeg`} alt={projectTitle} />
    </motion.div>
  )
}

const Projects = () => {
  return (
    <section id="projects" className="pt-48 pb-48">
      {/* HEADINGS */}

      <motion.div
        className="md:w-2/4 mx-auto text-center"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, y: -50 },
          visible: { opacity: 1, y: 0 }
        }}
      >
        <div>
          <p className="font-playFair font-semibold text-4xl">
            <span className="text-yellow">PRO</span>JECTS
          </p>
          <div className="flex justify-center mt-5">
          <LineGradient width="w-1/3" />
          </div>
        </div>
        <p className="mt-10 mb-10">
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nostrum assumenda temporibus vero facere alias ipsam sint, perferendis ipsum magni, fuga delectus aspernatur inventore totam ipsa architecto ullam tenetur, at reiciendis?
        </p>
      </motion.div>

      {/* PROJECTS */}
      <div className="flex justify-center">
        <motion.div
          className="sm:grid sm:grid-cols-3"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, amount: 0.2 }}
          variants={container}
        >
          {/* ROW 1 */}
          <div
            className="flex justify-center text-center items-center p-10 bg-red
              max-w-[400px] max-h-[400px] text-2xl font-playFair font-semibold"
          >
            BEAUTIFUL USER INTERFACES
          </div>
          <Project 
            title="myHeadphones" 
            subTitle="Ecommerce website built with React, Nextjs, context API, sanity.io, and Stripe integration."
            liveSite={"https://myheadphones-ecommerce.vercel.app"}
            gitHub={"https://github.com/Elybourland/ecommerce/tree/main/ecommerce"}
          />
          <Project title="Project 2" />

          {/* ROW 2 */}
          <Project title="Project 3" />
          <Project title="Project 4" />
          <Project title="Project 5" />

          {/* ROW 3 */}
          <Project title="Project 6" />
          <Project title="Project 7" />
          <div
            className="flex justify-center text-center items-center p-10 bg-blue
              max-w-[400px] max-h-[400px] text-2xl font-playFair font-semibold"
          >
            SMOOTH USER EXPERIENCE
          </div>

        </motion.div>
      </div>
    </section>
  )
}

export default Projects;