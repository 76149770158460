import SocialMediaIcons from "../components/SocialMediaIcons";

const Footer = () => {
  return (
    <footer className="h-64 bg-blue pt-10 border-t-2 border-t-white">
      <div className="w-5/6 mx-auto">
        <SocialMediaIcons />
        <div className="md:flex justify-center md:justify-between text-center">
          <p className="font-playFair font-semibold text-2xl text-white">ELY BOURLAND</p>
          <p className="font-playFair text-md text-white">&copy;2022 BOURLAND. All Rights Reserved.</p>
        </div>
      </div>
    </footer>
  )
};

export default Footer;