const SocialMediaIcons = () => {
  return (
    <div className="flex justify-center md:justify-start my-10 gap-7">
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.linkedin.com/in/jeremiah-ely-bourland"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="linkedin-link" src="../assets/linkedin.svg" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="https://www.github.com/Elybourland"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="github-link" src="../assets/github.svg" />
      </a>
      <a
        className="hover:opacity-50 transition duration-500"
        href="../assets/Jeremiah-Ely-Bourland-Resume.pdf"
        // download="Jeremiah-Ely-Bourland-Resume.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <img alt="resume-link" src="../assets/resume.svg" />
      </a>
    </div>
  )
}

export default SocialMediaIcons;